<template>
	<div class="footer-content">
		<strong>Antony LOURENÇO</strong>
		<div>
			<span v-html="$t('signature_frontend')" :data-text="$t('signature_frontend')"></span>
			<!-- <span v-html="$t('signature_integration')"></span>
			<span class="and" v-html="$t('signature_and')"></span>
			<span v-html="$t('signature_webdesign')"></span> -->
		</div>
		<div class="mt-3" v-html="$t('footer_info')"></div>
	</div>
</template>

<script>
export default {
}
</script>