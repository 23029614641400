<template>
	<div class="tool-card" :class="'tool-' + card.title.toLowerCase()" @click.stop>
		<div class="card-header">
			<h4 v-html="card.title"></h4>
			<div class="tool-logo" :class="card.title.toLowerCase()"></div>
		</div>
		<div class="text">
			<p v-html="$t(`tools_content_${card.id}`)"></p>
		</div>
		<a class="link" :href="card.link" target="_blank">
			<span v-html="card.title"></span> 
			<span class="icons Surfing"></span>
		</a>
	</div>
</template>

<script>
export default {
	props: ["card"],
	data() {
		return {
			more: false,
		}
	},
}
</script>