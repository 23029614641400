import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "d-flex justify-end mb-2 mr-4" };
const _hoisted_2 = { class: "d-flex align-center" };
const _hoisted_3 = {
    key: 0,
    class: "ml-2"
};
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { key: 1 };
const _hoisted_6 = { key: 2 };
const _hoisted_7 = { key: 3 };
const _hoisted_8 = { key: 4 };
import { inject, computed } from 'vue';
import { useRoute } from 'vue-router';
import AppModalDialog from '@assets/js/showcase/components/ui-elements/AppModalDialog.vue';
import ShowCaseEditEntryForm from '@assets/js/showcase/ShowCaseEditEntryForm.vue';
import ShowCaseConfirmDeletion from '@assets/js/showcase/ShowCaseConfirmDeletion.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainShowCaseCandidateDetail',
    setup(__props) {
        const route = useRoute();
        const candidateId = Number(route.params.id); // This should match the :id parameter in the route
        const tableState = inject('tableState');
        // Define the correct type for a single row in the table (candidate)
        const selectedCandidate = computed(() => {
            if (tableState) {
                const candidate = tableState.rows.find(candidate => candidate.id === candidateId);
                return candidate || null; // Return the candidate or null if not found
            }
            return null; // Return null when tableState is not available
        });
        const removeRow = (rowID) => {
            if (tableState?.rows) {
                tableState.rows = tableState.rows.filter(row => row.id !== rowID);
                window.location.href = "/showcase/";
            }
        };
        const goToLinkedIn = () => {
            window.open("https://www.linkedin.com/in/antonylourenco/", "_blank");
        };
        return (_ctx, _cache) => {
            const _component_v_btn = _resolveComponent("v-btn");
            const _component_v_icon = _resolveComponent("v-icon");
            const _component_v_col = _resolveComponent("v-col");
            const _component_v_row = _resolveComponent("v-row");
            const _component_v_card = _resolveComponent("v-card");
            const _component_v_container = _resolveComponent("v-container");
            return (_openBlock(), _createBlock(_component_v_container, null, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_v_btn, {
                            variant: "tonal",
                            icon: "mdi-arrow-left",
                            to: "/showcase"
                        })
                    ]),
                    _createVNode(_component_v_card, { class: "rounded-xl" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_v_col, null, {
                                default: _withCtx(() => [
                                    (selectedCandidate.value)
                                        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_v_col, null, {
                                                    default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_2, [
                                                            _createVNode(_component_v_icon, { size: "x-large" }, {
                                                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                                                    _createTextVNode("mdi-account-details")
                                                                ])),
                                                                _: 1
                                                            }),
                                                            (selectedCandidate.value.name)
                                                                ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(selectedCandidate.value.name), 1))
                                                                : _createCommentVNode("", true)
                                                        ]),
                                                        (selectedCandidate.value.email)
                                                            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                                                                _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Email:", -1)),
                                                                _createTextVNode(" " + _toDisplayString(selectedCandidate.value.email), 1)
                                                            ]))
                                                            : _createCommentVNode("", true),
                                                        (selectedCandidate.value.age)
                                                            ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                                                                _cache[4] || (_cache[4] = _createElementVNode("strong", null, "Age:", -1)),
                                                                _createTextVNode(" " + _toDisplayString(selectedCandidate.value.age), 1)
                                                            ]))
                                                            : _createCommentVNode("", true),
                                                        (selectedCandidate.value.phone)
                                                            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                                                                _cache[5] || (_cache[5] = _createElementVNode("strong", null, "Phone:", -1)),
                                                                _createTextVNode(" " + _toDisplayString(selectedCandidate.value.phone), 1)
                                                            ]))
                                                            : _createCommentVNode("", true),
                                                        (selectedCandidate.value.address)
                                                            ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                                                                _cache[6] || (_cache[6] = _createElementVNode("strong", null, "Address:", -1)),
                                                                _createTextVNode(" " + _toDisplayString(selectedCandidate.value.address), 1)
                                                            ]))
                                                            : _createCommentVNode("", true),
                                                        (selectedCandidate.value.occupation)
                                                            ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                                                                _cache[7] || (_cache[7] = _createElementVNode("strong", null, "Occupation:", -1)),
                                                                _createTextVNode(" " + _toDisplayString(selectedCandidate.value.occupation), 1)
                                                            ]))
                                                            : _createCommentVNode("", true)
                                                    ]),
                                                    _: 1
                                                }),
                                                (selectedCandidate.value.availability)
                                                    ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_v_btn, {
                                                                onClick: _cache[0] || (_cache[0] = ($event) => (goToLinkedIn())),
                                                                color: "green-accent-3",
                                                                variant: "outlined"
                                                            }, {
                                                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                                    _createTextVNode("Contact")
                                                                ])),
                                                                _: 1
                                                            })
                                                        ]),
                                                        _: 1
                                                    }))
                                                    : _createCommentVNode("", true),
                                                _createVNode(_component_v_col, {
                                                    cols: "12",
                                                    md: "1",
                                                    class: "d-flex flex-column align-end"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(AppModalDialog, {
                                                            btnDisabled: selectedCandidate.value.availability,
                                                            btnTooltip: 'Edit Candidate',
                                                            btnVariant: 'tonal',
                                                            modalSlug: 'edit-entry-modal',
                                                            modalTitle: 'Edit Candidate',
                                                            modalHeader: 'Edit Candidate',
                                                            btnClasses: 'mb-2',
                                                            btnColor: 'indigo-accent-3',
                                                            btnIcon: 'mdi-pencil-outline'
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(ShowCaseEditEntryForm, {
                                                                    candidateId: selectedCandidate.value.id
                                                                }, null, 8, ["candidateId"])
                                                            ]),
                                                            _: 1
                                                        }, 8, ["btnDisabled"]),
                                                        _createVNode(AppModalDialog, {
                                                            btnDisabled: selectedCandidate.value.availability,
                                                            btnIcon: 'mdi-delete-outline',
                                                            modalSlug: 'new-entry-modal',
                                                            modalTitle: 'Delete Candidate',
                                                            modalHeader: 'Are you Sure?',
                                                            btnTooltip: 'Delete Candidate',
                                                            btnVariant: 'tonal',
                                                            btnColor: 'red-accent-3'
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(ShowCaseConfirmDeletion, {
                                                                    onConfirmDeletion: _cache[1] || (_cache[1] = ($event) => (removeRow(selectedCandidate.value.id)))
                                                                })
                                                            ]),
                                                            _: 1
                                                        }, 8, ["btnDisabled"])
                                                    ]),
                                                    _: 1
                                                })
                                            ]),
                                            _: 1
                                        }))
                                        : _createCommentVNode("", true)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }));
        };
    }
});
